/* eslint-disable react-refresh/only-export-components */
import {lazy, StrictMode, Suspense} from "react"
import {createRoot} from "react-dom/client"
import LoadingPage from "./components/LoadingPage.tsx"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import {registerSW} from "virtual:pwa-register"

registerSW({immediate: true})

const mountElement = document.getElementById("app")
if (!mountElement) {
  throw new Error("No mount element found")
}

window.addEventListener("unhandledrejection", event => {
  if (event.reason === "Request cancelled") {
    event.preventDefault()
  }
})

const App = lazy(() => import("./App"))
// Inject our app into the DOM
createRoot(mountElement).render(<StrictMode>
  <Suspense fallback={<LoadingPage/>}>
    <App/>
  </Suspense>
</StrictMode>)
